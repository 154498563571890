<!--
 * @Author: 候怀烨
 * @Date: 2021-01-07 16:42:58
 * @LastEditTime: 2021-02-22 10:38:45
 * @LastEditors: Please set LastEditors
 * @Description: 兑奖活动
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\redeem\index.vue
-->
<template>
  <!-- NAME[epic=动销] 兑奖介质 -->
  <div style="padding: 20px">
    <el-row>
      <el-col :span="18">
        <el-form ref="form" inline :model="form">
          <el-form-item prop="keyword_jiezhi">
            <el-input
              v-model="form.keyword_jiezhi"
              placeholder="介质名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="keyword_goods">
            <el-input
              v-model="form.keyword_goods"
              placeholder="商品名称，助记码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查 询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="handlerAddm">添加兑奖活动</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe :data="tableData">
        <el-table-column
          align="center"
          label="兑奖活动"
          prop="goods_name"
          width="300"
        ></el-table-column>
        <el-table-column align="center" label="兑奖开关" width="150">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_close"
              :active-value="0"
              :inactive-value="1"
              @change="handlerSwitch(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="handlerEmit(scope.row)">
              编辑
            </el-button>
            <el-popconfirm
              title="您真的要删除这个活动介质吗？"
              @confirm="handlerdelete(scope.row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <add-medium ref="medium" @getlist="handlerRefresh"></add-medium>
  </div>
</template>

<script>
  import AddMedium from './components/AddMedium.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AddMedium,
    },
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
          keyword_jiezhi: '',
          keyword_goods: '',
        },
        url: {
          list: '/promoteAdmin/prize/index',
          switch: '/promoteAdmin/prize/status',
          delete: '/promoteAdmin/prize/del',
        },
        tableData: [],
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerRefresh() {
        this.handlerInquire()
      },
      handlerdelete(row) {
        if (row.is_close == 1) {
          postAction(this.url.delete, { id: row.cash_id })
            .then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.handlerInquire()
                this.$notify({
                  title: '成功',
                  message: '删除成功，该兑奖活动已被删除',
                  type: 'success',
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$message({
            message: '警告哦，删除前需要先关闭兑奖开关',
            type: 'warning',
          })
        }
      },
      handlerEmit(val) {
        this.$refs.medium.handlerdata(val)
        this.$refs.medium.Medium = true
      },
      handlerAddm() {
        this.$refs.medium.form.id = null
        this.$refs.medium.Medium = true
      },
      handlerSwitch(row) {
        console.log(row)
        postAction(this.url.switch, { id: row.cash_id })
          .then((res) => {
            console.log(res)
            this.handlerInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
