<!--
 * @Author: 候怀烨
 * @Date: 2021-01-08 11:32:46
 * @LastEditTime: 2021-03-29 11:11:44
 * @LastEditors: Please set LastEditors
 * @Description: 添加介质
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\redeem\components\AddMedium.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="title"
      width="1100px"
      top="5vh"
      :visible.sync="Medium"
      center
    >
      <div>
        <el-table stripe style="width: 70%" :data="listDatas" height="200px">
          <el-table-column
            v-for="(list, index) in colemd1"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
          >
            <template
              v-if="list.label == '介质名称'"
              #default="{ $index, row }"
            >
              <goods-search
                ref="goodsSearch1"
                :f-key="row.goods_name"
                :search="true"
                @add-rows="addRows1"
                @select-goods-all="selectGoods1($event, row, $index)"
              ></goods-search>
            </template>
            <template v-else-if="list.label == '单位'" #default="{ row }">
              <el-select
                v-model="row.unit_id"
                placeholder="选择单位"
                style="width: 100px"
                @change="unitChange($event, row)"
              >
                <el-option
                  v-for="item in row.arr_unit"
                  :key="item.id"
                  :label="item.unit_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
            <template v-else #default="{ row }">
              {{ row[list.prop] }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 添加入库单 -->
        <el-row>
          <el-table stripe :data="tableData" height="300px">
            <el-table-column type="index" width="60"></el-table-column>
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              :width="list.width"
            >
              <template
                v-if="list.label == '兑奖商品名称'"
                #default="{ $index, row }"
              >
                <!-- :goods-id="row.goods_id"
                  :kouwei="row.child"
                  :rowf="row"
                  @change-kouwei="changeKouwei($event, row)" -->
                <goods-search
                  :f-key="row.goods_name"
                  :show-kouwei="false"
                  @add-rows="addRows"
                  @select-goods-all="selectGoods($event, row, $index)"
                ></goods-search>
              </template>
              <!-- <template v-else-if="list.label == '生产日期'" #default="{ row }">
                <el-date-picker
                  v-model="row.state_time"
                  style="width: 140px"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </template> -->
              <template v-else-if="list.label == '数量'" #default="{ row }">
                <el-input
                  v-model="row.goods_num"
                  style="width: 80px"
                  @input="handleSum"
                />
              </template>
              <template v-else-if="list.label == '单位'" #default="{ row }">
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 100px"
                  @change="unitChange($event, row), handleSum()"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="list.label == '货值'" #default="{ row }">
                {{ Number(row.goods_num * row.goods_price).toFixed(2) }}
              </template>
              <template v-else-if="list.label == '单价'" #default="{ row }">
                <el-input
                  v-model="row.goods_price"
                  style="width: 80px"
                  onkeyup="value=value.replace(/[^\d.]/g,'') "
                  @input="handleSum"
                />
              </template>
              <template v-else #default="{ row }">
                {{ row[list.prop] }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              fixed="right"
              width="120"
            >
              <template #default="{ row, $index }">
                <el-button type="text" @click="handlerAdd(row, $index)">
                  添加
                </el-button>
                <el-button type="text" @click="handlerDelet(row, $index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-form ref="form" :model="form">
            <el-form-item label="兑换现金：" prop="prize_money">
              <el-input
                v-model="form.prize_money"
                style="width: 150px"
                placeholder="请输入金额"
                onkeyup="value=value.replace(/[^\-?\d.]/g,'') "
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="货值合计：" prop="prize_goods_money">
              <el-input
                v-model="form.prize_goods_money"
                style="width: 150px"
                disabled
                onkeyup="value=value.replace(/[^\d.]/g,'') "
              ></el-input>
            </el-form-item>
            <el-form-item label="兑奖规则：" prop="rules">
              <el-input
                v-model="form.rules"
                style="width: 150px"
                placeholder="请输入个数"
                onkeyup="value=value.replace(/[^\d.]/g,'') "
              ></el-input>
              个 介质兑换1份奖品
            </el-form-item>
            <el-form-item label="修改奖品：" prop="flag_edit_prize">
              <el-switch
                v-model="form.flag_edit_prize"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <span style="margin-left: 20px">允许超额修改：</span>
              <el-switch
                v-model="form.flag_edit_excess"
                :disabled="form.flag_edit_prize != 1"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
            <el-form-item prop="flag_abnormal">
              <el-radio-group
                v-model="form.flag_abnormal"
                :disabled="form.flag_edit_prize != 1"
              >
                <el-radio
                  v-for="city in cities"
                  :key="city.id"
                  :label="city.id"
                >
                  {{ city.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="限定业务员：" prop="user_data">
              <span style="margin-right: 15px">{{ userList.length }}人</span>
              <el-button type="primary" @click="handleSet">设置</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <set-component
          ref="setComponent"
          :is-create="true"
          @set-userid="setUserId"
          @r-table-data="setUser"
        ></set-component>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-show="showbutton == 1"
          type="primary"
          @click="handlerCommit"
        >
          保 存
        </el-button>
        <el-button
          v-show="showbutton == 2"
          type="primary"
          @click="handlerUpdated"
        >
          保 存
        </el-button>
        <el-button @click="Medium = !Medium">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import _ from 'lodash'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      GoodsSearch,
      SetComponent,
    },
    data() {
      return {
        form: {
          prize_goods_money: '',
          prize_money: '',
          rules: '',
          flag_edit_prize: 1,
          flag_edit_excess: 0,
          flag_abnormal: '0',
          user_data: [],
        },
        user_data: 0,
        cities: [
          {
            name: '更换奖品不提示异常',
            id: '0',
          },
          {
            name: '更换奖品提示异常',
            id: '1',
          },
          {
            name: '超额更换奖品提示异常',
            id: '2',
          },
        ],
        listDatas: [
          {
            arr_unit: [], //没有用的
            goods_id: '',
            goods_price: '', //没有用的
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
          },
        ],
        tableData: [
          {
            arr_unit: [],
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
            goods_num: '',
            goods_price: '',
          },
        ],
        checkList: [
          '兑奖商品名称',
          '单位',
          '入库数量',
          '单价',
          '金额',
          '生产日期',
          '备注',
        ],
        colemd1: [
          {
            order: 1,
            label: '介质名称',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 1,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '120',
          },
          {
            order: 1,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '140',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '兑奖商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '',
          },

          {
            order: 4,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 6,
            label: '数量',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 5,
            label: '货值',
            align: 'center',
            prop: 'goods_money',
            width: '',
          },
        ],
        // 限定业务员
        userList: [],
        title: '添加兑奖活动',
        Medium: false,
        url: {
          commit: '/promoteAdmin/prize/create',
          data: '/promoteAdmin/prize/detail',
          updated: '/promoteAdmin/prize/update',
        },
        showbutton: 1,
      }
    },
    watch: {
      'form.flag_edit_prize'(val) {
        //do something
        console.log(val)
        if (val == 0) {
          this.form.flag_edit_excess = 0
          this.form.flag_abnormal = ''
        } else {
          this.form.flag_abnormal = '0'
        }
      },
      Medium(val) {
        console.log(val)
        if (val == false) {
          this.showbutton = 1
          this.$refs.setComponent.rTableData = []
          this.userList = []
          this.title = '添加兑奖活动'
          this.tableData = [
            {
              arr_unit: [],
              goods_id: '',
              goods_name: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
              goods_num: '',
              goods_price: '',
            },
          ]
          this.listDatas = [
            {
              arr_unit: [], //没有用的
              goods_id: '',
              goods_price: '', //没有用的
              goods_name: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
            },
          ]
          this.user_data = 0
          this.$refs['form'].resetFields()
        }
      },
    },
    methods: {
      handlerUpdated() {
        var goods_data = this.listDatas
        var prize_data = this.tableData
        if (this.listDatas.length > 0) {
          prize_data = prize_data.filter((item) => {
            return item.goods_name != ''
          })
          goods_data = goods_data.filter((item) => {
            return item.goods_name != ''
          })
          console.log(goods_data, prize_data, '打印的是个啥')
          if (goods_data.length > 0) {
            this.$set(this.form, 'goods_data', JSON.stringify(goods_data))
            this.$set(this.form, 'prize_data', JSON.stringify(prize_data))
            // this.form.user_data = JSON.stringify(this.form.user_data)
            postAction(this.url.updated, {
              ...this.form,
              ...{
                user_data:
                  this.userList.length == 0
                    ? ''
                    : JSON.stringify(this.userList),
              },
            })
              .then((res) => {
                console.log(res)
                this.$message({
                  showClose: true,
                  message: res.msg,
                })
                this.Medium = false
                this.$refs['form'].resetFields()
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.handlerjznull()
          }
        } else {
          this.handlerjznull()
        }
      },
      handlerdata(val) {
        console.log(val)
        this.showbutton = 2
        this.title = '编辑兑奖活动'
        postAction(this.url.data, { id: val.cash_id })
          .then((res) => {
            console.log(res, '兑奖详情')
            this.tableData = res.data.prize_data
            // this.tableData.forEach((item) => {
            // this.$set(item, 'goods_num', item.quantity)
            // })
            this.listDatas = res.data.goods_data
            this.userList = res.data.user_data
            // if (this.tableData) {
            //   this.tableData.push({
            //     arr_unit: '', //没有用的
            //     goods_id: '',
            //     goods_price: '', //没有用的
            //     goods_name: '',
            //     goods_specs: '',
            //     unit_id: '',
            //     unit_name: '',
            //     goods_num: '',
            //   })
            // } else {
            //   this.tableData = [
            //     {
            //       arr_unit: '', //没有用的
            //       goods_id: '',
            //       goods_price: '', //没有用的
            //       goods_name: '',
            //       goods_specs: '',
            //       unit_id: '',
            //       unit_name: '',
            //       goods_num: '',
            //     },
            //   ]
            // }
            // if (this.listDatas.length != 1) {
            //   // this.listDatas.push({
            //   //   arr_unit: '',
            //   //   goods_id: '',
            //   //   goods_name: '',
            //   //   goods_specs: '',
            //   //   unit_id: '',
            //   //   unit_name: '',
            //   //   goods_num: '',
            //   //   goods_price: '',
            //   // })
            // } else {
            //   this.listDatas = [
            //     {
            //       arr_unit: '',
            //       goods_id: '',
            //       goods_name: '',
            //       goods_specs: '',
            //       unit_id: '',
            //       unit_name: '',
            //       goods_num: '',
            //       goods_price: '',
            //     },
            //   ]
            // }

            this.form = {
              prize_goods_money: res.data.prize_goods_money,
              prize_money: res.data.prize_money,
              rules: res.data.prize_guize,
              flag_edit_prize: res.data.flag_edit_prize,
              flag_edit_excess: res.data.flag_edit_excess,
              // flag_abnormal: toString(res.data.flag_abnormal),
              flag_abnormal: res.data.flag_abnormal,
              user_data: res.data.user_data,
            }
            this.$set(this.form, 'id', res.data.id)
            this.user_data = res.data.user_data.length
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // 计算合计
      handleSum() {
        let sum = 0
        this.tableData.forEach((i) => {
          if (i.goods_name) {
            sum += Number(i.goods_num) * Number(i.goods_price)
          }
        })
        sum = sum.toFixed(2)
        this.form.prize_goods_money = sum || 0
      },
      handlerCommit() {
        var goods_data = this.listDatas
        var prize_data = this.tableData
        if (this.listDatas.length > 0) {
          prize_data = prize_data.filter((item) => {
            return item.goods_name != ''
          })
          goods_data = goods_data.filter((item) => {
            return item.goods_name != ''
          })
          console.log(goods_data, prize_data, '打印的是个啥')
          if (goods_data.length > 0) {
            delete goods_data.arr_unit
            delete prize_data.arr_unit
            let p = prize_data.length == 0 ? '' : JSON.stringify(prize_data)
            this.$set(this.form, 'goods_data', JSON.stringify(goods_data))
            this.$set(this.form, 'prize_data', p)
            // this.form.user_data = JSON.stringify(this.form.user_data)
            let user_data = []
            this.userList.forEach((i) => {
              if (i.id) {
                user_data.push(i.id)
              }
            })
            postAction(this.url.commit, {
              ...this.form,
              ...{
                user_data:
                  user_data.length == 0 ? '' : JSON.stringify(user_data),
              },
            })
              .then((res) => {
                console.log(res)
                this.$message({
                  showClose: true,
                  message: res.msg,
                })
                this.Medium = false
                this.$refs['form'].resetFields()
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.handlerjznull()
          }
        } else {
          this.handlerjznull()
        }
      },
      //介质名不能为空
      handlerjznull() {
        this.$alert('介质商品不能为空', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {
            this.$message({
              type: 'info',
              message: `action: ${action}`,
            })
          },
        })
      },
      setUser(val) {
        this.userList = val
      },
      setUserId(val) {
        console.log(val, val.split(','))
        this.form.user_data = val.split(',')
        this.user_data = val.split(',').length
        // this.data.limit_count = this.data.limit_users.split(',').length
      },
      handleSet() {
        this.$refs.setComponent.rTableData = JSON.parse(
          JSON.stringify(this.userList)
        )
        if (this.form.id) this.$refs.setComponent.getUserList(this.form.id)
        this.$refs.setComponent.showDialog = true
      },
      // 添加
      handlerAdd(row, index) {
        this.tableData.splice(index + 1, 0, JSON.parse(JSON.stringify(row)))
      },
      // 删除
      handlerDelet(row, index) {
        if (this.tableData.length <= 1) {
          this.$message({
            message: '不能再删除了呀',
            type: 'warning',
          })
        } else {
          this.tableData.splice(index, 1)
        }
      },
      selectGoods1(event, row, index) {
        console.log(event, row.goods_name, '1shenme')
        var data = {
          arr_unit: event.arr_unit, //没有用的
          goods_id: event.goods_id,
          goods_price: event.goods_price, //没有用的
          goods_name: event.goods_name,
          goods_specs: event.specs,
          unit_id: event.unit_id,
          unit_name: event.unit_name,
        }
        // Object.assign(row, event)
        Object.assign(row, data)
        console.log(row.goods_name, '商品名称')
        if (this.listDatas[this.listDatas.length - 1].goods_name == '') {
        } else {
          var a = {
            arr_unit: [], //没有用的
            goods_id: '',
            goods_price: '', //没有用的
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
          }
          // this.listDatas.push(a)
        }
      },
      selectId(event, row) {
        console.log(event, row, '2')
      },
      addRows1(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item, index) => {
            if (index == 0) {
              this.listDatas = []
              var a = {
                arr_unit: item.arr_unit, //没有用的
                goods_id: item.goods_id,
                goods_price: item.goods_price, //没有用的
                goods_name: item.goods_name,
                goods_specs: item.specs,
                unit_id: item.unit_id,
                unit_name: item.unit_name,
              }
              item.goodsName = item.goods_name
              this.listDatas.push(a)
              // this.listDatas.splice(this.listDatas.length - 1, 0, a)
            }
          })
          this.$message.success('添加成功')
        }
      },
      selectGoods(event, row, index) {
        console.log(event, row, '1')
        var data = {
          arr_unit: event.arr_unit,
          goods_id: event.goods_id,
          goods_name: event.goods_name,
          goods_specs: event.specs,
          unit_id: event.unit_id,
          unit_name: event.unit_name,
          goods_num: event.quantity,
          goods_price: event.goods_price,
          child: event.child,
        }
        // Object.assign(row, event)
        Object.assign(row, data)
      },

      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              arr_unit: item.arr_unit,
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              goods_specs: item.specs,
              unit_id: item.unit_id,
              unit_name: item.unit_name,
              goods_num: item.quantity,
              goods_price: item.goods_price,
            }
            item.goodsName = item.goods_name
            this.tableData.splice(this.tableData.length - 1, 0, a)
          })
          this.$message.success('添加成功')
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        row.goods_price = price
        row.unit_name = name
      },
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
    },
  }
</script>

<style></style>
