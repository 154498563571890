var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.title,
            width: "1100px",
            top: "5vh",
            visible: _vm.Medium,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Medium = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "70%" },
                  attrs: { stripe: "", data: _vm.listDatas, height: "200px" },
                },
                _vm._l(_vm.colemd1, function (list, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      label: list.label,
                      align: list.align,
                      prop: list.prop,
                    },
                    scopedSlots: _vm._u(
                      [
                        list.label == "介质名称"
                          ? {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  _c("goods-search", {
                                    ref: "goodsSearch1",
                                    refInFor: true,
                                    attrs: {
                                      "f-key": row.goods_name,
                                      search: true,
                                    },
                                    on: {
                                      "add-rows": _vm.addRows1,
                                      "select-goods-all": function ($event) {
                                        return _vm.selectGoods1(
                                          $event,
                                          row,
                                          $index
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            }
                          : list.label == "单位"
                          ? {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: { placeholder: "选择单位" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.unitChange($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.unit_id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "unit_id", $$v)
                                        },
                                        expression: "row.unit_id",
                                      },
                                    },
                                    _vm._l(row.arr_unit, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.unit_name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            }
                          : {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(" " + _vm._s(row[list.prop]) + " "),
                                ]
                              },
                            },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        stripe: "",
                        data: _vm.tableData,
                        height: "300px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "60" },
                      }),
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: list.width,
                          },
                          scopedSlots: _vm._u(
                            [
                              list.label == "兑奖商品名称"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var $index = ref.$index
                                      var row = ref.row
                                      return [
                                        _c("goods-search", {
                                          attrs: {
                                            "f-key": row.goods_name,
                                            "show-kouwei": false,
                                          },
                                          on: {
                                            "add-rows": _vm.addRows,
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "数量"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: { input: _vm.handleSum },
                                          model: {
                                            value: row.goods_num,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_num", $$v)
                                            },
                                            expression: "row.goods_num",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "单位"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: { placeholder: "选择单位" },
                                            on: {
                                              change: function ($event) {
                                                _vm.unitChange($event, row),
                                                  _vm.handleSum()
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(row.arr_unit, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.unit_name,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : list.label == "货值"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Number(
                                                row.goods_num * row.goods_price
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  }
                                : list.label == "单价"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            onkeyup:
                                              "value=value.replace(/[^\\d.]/g,'') ",
                                          },
                                          on: { input: _vm.handleSum },
                                          model: {
                                            value: row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_price", $$v)
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " + _vm._s(row[list.prop]) + " "
                                        ),
                                      ]
                                    },
                                  },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          fixed: "right",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerAdd(row, $index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerDelet(row, $index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    { ref: "form", attrs: { model: _vm.form } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "兑换现金：", prop: "prize_money" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                placeholder: "请输入金额",
                                onkeyup:
                                  "value=value.replace(/[^\\-?\\d.]/g,'') ",
                              },
                              model: {
                                value: _vm.form.prize_money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "prize_money", $$v)
                                },
                                expression: "form.prize_money",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "货值合计：",
                            prop: "prize_goods_money",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              disabled: "",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.prize_goods_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "prize_goods_money", $$v)
                              },
                              expression: "form.prize_goods_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "兑奖规则：", prop: "rules" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              placeholder: "请输入个数",
                              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                            },
                            model: {
                              value: _vm.form.rules,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rules", $$v)
                              },
                              expression: "form.rules",
                            },
                          }),
                          _vm._v(" 个 介质兑换1份奖品 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "修改奖品：",
                            prop: "flag_edit_prize",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.flag_edit_prize,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "flag_edit_prize", $$v)
                              },
                              expression: "form.flag_edit_prize",
                            },
                          }),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v("允许超额修改：")]
                          ),
                          _c("el-switch", {
                            attrs: {
                              disabled: _vm.form.flag_edit_prize != 1,
                              "active-value": 1,
                              "inactive-value": 0,
                            },
                            model: {
                              value: _vm.form.flag_edit_excess,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "flag_edit_excess", $$v)
                              },
                              expression: "form.flag_edit_excess",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "flag_abnormal" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.form.flag_edit_prize != 1,
                              },
                              model: {
                                value: _vm.form.flag_abnormal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "flag_abnormal", $$v)
                                },
                                expression: "form.flag_abnormal",
                              },
                            },
                            _vm._l(_vm.cities, function (city) {
                              return _c(
                                "el-radio",
                                { key: city.id, attrs: { label: city.id } },
                                [_vm._v(" " + _vm._s(city.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "限定业务员：", prop: "user_data" } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "15px" } },
                            [_vm._v(_vm._s(_vm.userList.length) + "人")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSet },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("set-component", {
                ref: "setComponent",
                attrs: { "is-create": true },
                on: {
                  "set-userid": _vm.setUserId,
                  "r-table-data": _vm.setUser,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showbutton == 1,
                      expression: "showbutton == 1",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerCommit },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showbutton == 2,
                      expression: "showbutton == 2",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerUpdated },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.Medium = !_vm.Medium
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }